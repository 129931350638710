import request from '@/until/request.js'
// const prefixName = '/yny/productType'

// 获取供应商
export function getSupplier(data){
  return request({
    url:"/gm/supplier",
    method: 'get',
    params: { ...data }
  })
}
// 获取采购汇总表列表
export function getSummaryProduct(data){
  return request({
    url:"/gm/wmsPurchaseReport/summaryProduct",
    method: 'get',
    params: { ...data }
  })
}
// 获取商品列表
export function getProductList(data){
  return request({
    url:"/gm/webPrdInfo",
    method: 'get',
    params: { ...data }
  })
}
// 获取仓库列表
export function getTheWarehouseList(data){
  return request({
    url:"/gm/webWms",
    method: 'get',
    params: { ...data }
  })
}
// 获取采购汇总表（供应商）
export function getSummarySupplier(data){
  return request({
    url:"/gm/webWmsPusRpt/summarySupplier",
    method: 'get',
    params: { ...data }
  })
}