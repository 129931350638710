<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="日期范围:" style="width: 450px">
        <el-date-picker
          v-model="pickerDate"
          type="daterange"
          range-separator="至"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          end-placeholder="结束日期"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="供应商:" style="width: 330px">
        <el-select v-model="searcForm.supplierIds" multiple placeholder="">
          <el-option
            v-for="item in suppliers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品:" style="width: 330px">
        <el-select v-model="searcForm.productIds" multiple placeholder="">
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="仓库:" style="width: 330px">
        <el-select v-model="searcForm.wmsIds" multiple placeholder="">
          <el-option
            v-for="item in storehouse"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" @click="searchForm">查询</el-button>
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <div class="footBtn">
      <div></div>
      <div></div>
    </div>
    <div class="bigbox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="code"
          label="商品编号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="spec" label="规格" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.spec ? scope.row.spec : "--" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="barCode"
          label="条形码"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.barCode ? scope.row.barCode : "--" }}
          </template>
        </el-table-column>
        <el-table-column label="采购入库" align="center">
          <el-table-column prop="purchaseNum" label="数量" align="center">
          </el-table-column>
          <el-table-column label="金额" align="center" prop="purchasePrice">
            <template slot-scope="scope">
              {{
                scope.row.purchasePrice
                  ? scope.row.purchasePrice.toFixed(2)
                  : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="采购退货" align="center">
          <el-table-column prop="purchaseRefundNum" label="数量" align="center">
            <template slot-scope="scope">
              {{
                scope.row.purchaseRefundNum ? scope.row.purchaseRefundNum : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="purchaseRefundPrice"
          >
            <template slot-scope="scope">
              {{
                scope.row.purchaseRefundPrice
                  ? scope.row.purchaseRefundPrice.toFixed(2)
                  : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <el-table-column prop="combinedNum" label="数量" align="center">
          </el-table-column>
          <el-table-column label="金额" align="center" prop="combinedPrice">
            <template slot-scope="scope">
              {{
                scope.row.combinedPrice
                  ? scope.row.combinedPrice.toFixed(2)
                  : "--"
              }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <table
        border="1"
        cellpadding="0"
        cellspacing="0"
        style="
          position: absolute;
          bottom: -48px;
          right: 0;
          border-color: #efffff;
          background-color: #fff;
          z-index: 1;
        "
      >
        <tr style="height: 48px">
          <td style="width: 149px; text-align: center; font-weight: bold">
            {{ totalNum.code }}
          </td>
          <td style="width: 149px; text-align: center; border-right: none"></td>
          <td style="width: 149px; text-align: center; border-right: none"></td>
          <td style="width: 149px; text-align: center; border-right: none"></td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.purchaseNum }}
          </td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.purchasePrice.toFixed(2) }}
          </td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.purchaseRefundNum }}
          </td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.purchaseRefundPrice.toFixed(2) }}
          </td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.combinedNum }}
          </td>
          <td style="width: 98px; text-align: center; border-right: none">
            {{ totalNum.combinedPrice.toFixed(2) }}
          </td>
        </tr>
      </table>
    </div>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import { datatimestatus } from "@/until/auth";
import {
  getSupplier,
  getSummaryProduct,
  getProductList,
  getTheWarehouseList,
} from "@/api/procurementSummary";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    OpenAccount,
  },
  data() {
    return {
      openAccountFlag: true,
      info: {
        show: false,
      },
      tableDatas: [], //导出组装的数据
      totalNum: {
        code: "合计",
        purchaseNum: 0,
        purchasePrice: 0,
        purchaseRefundNum: 0,
        purchaseRefundPrice: 0,
        combinedNum: 0,
        combinedPrice: 0,
      },
      storehouse: [], //仓库列表
      productList: [], //商品列表
      tableData: [], //表格数据
      suppliers: [], //供应商
      pickerOptions: null,
      pickerDate: [], //日期范围
      searcForm: {
        supplierIds: "",
        productIds: "",
        wmsIds: "",
        startDate: "",
        endDate: "",
      }, //搜索条件
    };
  },
  created() {
    this.getConfigBills();
    this.datatimestatus();
    this.getDate();
    this.getSupplier();
    this.getList();
    this.getProductList();
    this.getTheWarehouseList();
  },

  methods: {
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
          this.openAccountFlag = false;
        }
      }
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        const multiHeader = [
          [
            "商品编号",
            "商品名称",
            "规格",
            "条形码",
            "采购入库",
            "",
            "采购退货",
            "",
            "合计",
            "",
          ],
        ]; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = [
          "",
          "",
          "",
          "",
          "数量",
          "金额",
          "数量",
          "金额",
          "数量",
          "金额",
        ]; // 最后一行的表头数据
        const filterVal = [
          "code",
          "name",
          "spec",
          "barCode",
          "purchaseNum",
          "purchasePrice",
          "purchaseRefundNum",
          "purchaseRefundPrice",
          "combinedNum",
          "combinedPrice",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableDatas;
        const data = this.formatJson(filterVal, list);
        const merges = [
          "A1:A2",
          "B1:B2",
          "C1:C2",
          "D1:D2",
          "E1:F1",
          "G1:H1",
          "I1:J1",
        ]; // 合并单元格的数据，如何合并参考上面图片讲解
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename: "采购汇总表(按商品)",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },

    //获取仓库列表
    async getTheWarehouseList() {
      let res = await getTheWarehouseList();
      if (res.code == 1) {
        this.storehouse = res.data.records;
      }
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
    //查询按钮
    searchForm() {
      this.getList();
    },
    //获取列表
    async getList() {
      this.totalNum = {
        code: "合计",
        purchaseNum: 0,
        purchasePrice: 0,
        purchaseRefundNum: 0,
        purchaseRefundPrice: 0,
        combinedNum: 0,
        combinedPrice: 0,
      };
      let params = {
        supplierIds: this.searcForm.supplierIds,
        productIds: this.searcForm.productIds,
        wmsIds: this.searcForm.wmsIds,
        startDate: this.pickerDate[0],
        endDate: this.pickerDate[1],
      };

      let res = await getSummaryProduct(params);
      if (res.code == 1) {
        if (res.data) {
          res.data.forEach((item) => {
            this.totalNum.purchaseNum += item.purchaseNum;
            this.totalNum.purchasePrice += item.purchasePrice;
            this.totalNum.purchaseRefundNum += item.purchaseRefundNum;
            this.totalNum.purchaseRefundPrice += item.purchaseRefundPrice;
            this.totalNum.combinedNum += item.combinedNum;
            this.totalNum.combinedPrice += item.combinedPrice;
          });
          this.tableDatas = JSON.parse(JSON.stringify(res.data));
          this.tableDatas.push(this.totalNum);
          this.tableData = res.data;
        }
      }
    },
    //表格列求和
    // summaryMethod({ columns, data }) {
    //   console.log(data);
    //   const sums = [];
    //   if (columns.length > 0) {
    //     columns.forEach((column, index) => {
    //       if ([0].includes(index)) {
    //         sums[index] = "合计";
    //       }
    //       if (column.label == "金额") {
    //         let values = data.map((item) => Number(item[column.property]));
    //         if (!values.every((value) => isNaN(value))) {
    //           sums[index] = values.reduce((prev, curr) => {
    //             const value = Number(curr);
    //             if (!isNaN(value)) {
    //               let num = Number(prev) + Number(curr);
    //               return num.toFixed(2);
    //             } else {
    //               return Number(prev).toFixed(2);
    //             }
    //           }, 0.0);
    //         }
    //       } else if (column.label == "数量") {
    //         let values = data.map((item) => Number(item[column.property]));
    //         if (!values.every((value) => isNaN(value))) {
    //           sums[index] = values.reduce((prev, curr) => {
    //             const value = Number(curr);
    //             if (!isNaN(value)) {
    //               let num = Number(prev) + Number(curr);
    //               return num;
    //             } else {
    //               return Number(prev);
    //             }
    //           }, 0.0);
    //         }
    //       } else {
    //         return "";
    //       }
    //     });
    //   }
    //   return sums;
    // },
    // 获取供应商
    async getSupplier() {
      let res = await getSupplier();
      if (res.code == 1) {
        this.suppliers = res.data.records;
      }
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.pickerDate = defaultDate;
      // this.getData();
    },
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
};
</script>

<style scoped>
.bigbox {
  position: relative;
}
.F {
  display: flex;
  flex-wrap: wrap;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.footBtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
.view-wrap >>> .el-table__footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 18%;
}
.view-wrap {
  position: relative;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
</style>